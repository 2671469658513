<template>
  <div class="realEstate-page" style="min-height: 80vh">
    <div v-if="loaded === true">
      <!-- RealEstate statistics -->
      <b-row class="realEstate-statistics">
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="EyeIcon" statistic="36.9k" statistic-title="Actually viewed"/>
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="ShoppingBagIcon" color="success" statistic="97.8k"
                                     statistic-title="Booked but canceled"/>
        </b-col>
        <b-col lg="2" sm="2">
          <statistic-card-horizontal icon="MessageSquareIcon" color="warning" statistic="12k" statistic-title="Rented"/>
        </b-col>
        <b-col lg="2" sm="2">
          <statistic-card-horizontal icon="UserIcon" color="info" statistic="8.549k" statistic-title="Rating"/>
        </b-col>
        <b-col class="text-center align-content-center" lg="2" sm="2">
          <div>
            <b-button variant="primary" class="mx-1" @click="updateRealEstate">{{ msg("Save") }}</b-button>
            <b-button variant="warning" @click="loadRealEstate">{{ msg("Cancel") }}</b-button>
          </div>
        </b-col>

      </b-row>
      <b-tabs class="client-panel-tabs tabs-title" nav-class="d-inline-flex" v-model="tabIndex">
        <!--Pictures, Managemnt details, Geografic details,Areas details -->
        <b-tab v-if="requestId!=null" :title="msg('Email Administration')">

          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <!-- Header: Personal Info -->
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Email').toUpperCase() }}
                  </h4>
                </div>
                <real-estate-admin v-model="realEstate" @change="()=>realEstateUpdated=true" :request-id="requestId"/>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>
        <b-tab :title="msg('Management')">
          <div>

            <!--Managemnt details -->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Management').toUpperCase() }}
                    </h4>
                  </div>
                  <real-estate-management v-model="realEstate" @change="()=>realEstateUpdated=true"
                                          :building="building"/>
                </div>
              </b-card-body>
            </b-card>
            <!--Pictures-->
            <b-card no-body class="card-stats ">
              <b-card-header>
                <b-row no-gutters class="w-100">
                  <!-- Header: Personal Info -->
                  <b-col cols="6">
                    <div class="d-flex">
                      <i class="far fa-images"></i>
                      <h4 class="mb-0 ml-50">
                        {{ msg('Pictures').toUpperCase() }}
                      </h4>
                    </div>
                  </b-col>

                  <b-col cols="6" class="text-right">
                    <div>
                      <b-button variant="outline-primary" class="btn-icon" id="realEstate-upload-image">
                        <feather-icon icon="UploadIcon"/>
                      </b-button>
                      <div>
                        <b-popover target="realEstate-upload-image" size="xl">
                          <vue-dropzone id="video-dropzone"
                                        :options="dropzoneOptions"
                                        :useCustomSlot=true
                                        :duplicateCheck="true"
                                        @vdropzone-complete="uploadComplete"
                                        @vdropzone-success="uploadSuccess">
                            <div class="dropzone-custom-content">
                              <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                              <div class="subtitle">...or click to select a file from your computer</div>
                            </div>
                          </vue-dropzone>
                        </b-popover>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="d-flex">
                      <span>*Grundrisse die hochgeladen werden , müssen das Wort „Grundriss“ im Bildnamen enthalten.</span>
                    </div>
                  </b-col>
                </b-row>

              </b-card-header>
              <b-card-body>
                <realEstate-image-swiper v-model="realEstate.attachments" @change="realEstateUpdated=true"/>

              </b-card-body>
            </b-card>


            <!--Geografic details -->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <i class="fas fa-map-marked-alt"></i>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Geografic').toUpperCase() }}
                    </h4>
                  </div>
                  <real-estate-geo v-model="realEstate" @change="()=>realEstateUpdated=true"/>
                </div>
              </b-card-body>
            </b-card>
            <!--Areas details (size and rooms) -->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <i class="fas fa-ruler-combined"></i>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Areas').toUpperCase() }}
                    </h4>
                  </div>
                  <real-estate-area v-model="realEstate" @change="()=>realEstateUpdated=true"/>
                </div>
              </b-card-body>
            </b-card>
            <!--files-->
            <b-card style="height: auto;" border-variant="warning">
              <div>
                <div class="card-header d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <feather-icon icon="FileIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Files').toUpperCase() }}
                    </h4>
                  </div>
                  <div>
                    <b-button size="sm" v-bind:variant="uploadFile ? 'warning' : 'outline-warning'"
                              @click="()=>uploadFile =!uploadFile">
                      <i class="fas fa-upload"></i> {{ msg("Upload file") }}
                    </b-button>
                  </div>
                </div>


                <div class="files-data">
                  <div class="upload-backdrop" v-show="uploadFile === true">
                    <vue-dropzone id="contract-upload" class="h-100" :options="dropzoneOptionsFiles" :useCustomSlot=true
                                  :duplicateCheck="true" @vdropzone-complete="uploadCompleteFile"
                                  @vdropzone-success="uploadSuccessFile">
                      <div class="dropzone-custom-content">
                        <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                        <div class="subtitle">...or click to select a file from your computer</div>
                      </div>
                    </vue-dropzone>
                  </div>
                  <b-table-simple outlined>
                    <b-tr>
                      <b-th class="text-center" style="width: 20%">{{ msg('File') }}</b-th>
                      <b-th class="text-center" style="width: 20%">{{ msg('Tag') }}</b-th>
                      <!--
                                            <b-th class="text-center" style="width: 20%">{{ msg('Client note') }}</b-th>
                      -->
                      <b-th class="text-center" style="width: 20%">{{ msg('Note') }}</b-th>
                      <b-th class="text-center" style="width: 10%">{{ msg("Visible") }}</b-th>
                      <b-th class="text-center" style="width: 15%">{{ msg('Operations') }}</b-th>
                    </b-tr>
                  </b-table-simple>
                  <perfect-scrollbar class="position-relative " style="height: 250px">
                    <b-table-simple outlined>
                      <b-tr v-for="(file,index) in (realEstate.files || [])" v-bind:key="file.id"
                            style="white-space: nowrap;text-overflow: ellipsis" v-show="file.active">
                        <b-td style="width: 20%">
                          <i v-bind:class="iconByExt(file.filename)" class="mx-1"
                             style="font-size: 20px"></i>{{ file.filename }}
                        </b-td>
                        <b-td class="text-center" style="width: 20%">
                          <v-select :options="fileTags" :reduce="(tag) => tag.value" label="title" v-model="file.tag"
                                    append-to-body @input="()=>submitDocumentFile(index)">
                            <template v-slot:option="option">
                              <i class="d-inline-block text-left" style="width: 30px" :class="option.icon"></i>
                              <span>{{ option.title }}</span>
                            </template>
                          </v-select>
                        </b-td>
                        <!--                        <b-td class="text-center" style="width: 20%">
                                                  <b-form-textarea v-model="file.clientNote" @change="()=>submitDocumentFile(index)"
                                                                   size="sm"></b-form-textarea>
                                                </b-td>-->
                        <b-td class="text-center" style="width: 20%">
                          <b-form-textarea v-model="file.notes" @change="()=>submitDocumentFile(index)"
                                           size="sm"></b-form-textarea>
                        </b-td>
                        <b-td class="text-center" style="width: 10%">
                          <b-form-checkbox v-model="file.visible" class="mx-1" name="check-button" size="sm"
                                           @change="()=>submitDocumentFile(index)"
                                           v-b-tooltip="msg('visible to client')"
                                           button
                                           v-bind:button-variant="file.visible ? 'outline-success' : 'outline-warning'">
                            <i class="fas fa-user"></i>
                          </b-form-checkbox>
                          <b-form-checkbox v-model="file.visibleForOwner" name="check-button" size="sm"
                                           @change="()=>submitDocumentFile(index)"
                                           v-b-tooltip="msg('visible to owner')"
                                           button
                                           v-bind:button-variant="file.visibleForOwner ? 'outline-success' : 'outline-warning'">
                            <i class="fas fa-user-tie"></i>
                          </b-form-checkbox>
                        </b-td>
                        <b-td class="text-center" style="width: 15%">
                          <b-link class="text-secondary mx-1"
                                  @click="()=>onHideFile(index)"><i
                              class="fas fa-eye-slash"></i></b-link>
                          <b-link><i class="icon fas fa-download  w-auto" @click="()=>onDownloadFile(file.id)"></i>
                          </b-link>
                        </b-td>
                      </b-tr>
                    </b-table-simple>
                  </perfect-scrollbar>
                </div>
              </div>
            </b-card>

          </div>
        </b-tab>
        <!-- RealEstate Description -->
<!--        <b-tab :title="msg('Description')">
          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  &lt;!&ndash; Header: Personal Info &ndash;&gt;
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Description') }}
                    </h4>
                  </div>
                  <real-estate-text v-model="realEstate" @change="()=>realEstateUpdated=true"/>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-tab>-->
        <!--furniture details, Prices details  -->
        <b-tab :title="` ${msg('Prices')}`">
          <div>

            <!-- Prices details-->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <i class="fas fa-euro-sign"></i>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Prices').toUpperCase() }}
                    </h4>
                  </div>
                  <real-estate-prices v-model="realEstate" @change="()=>realEstateUpdated=true"/>
                </div>
              </b-card-body>
            </b-card>

          </div>
        </b-tab>
        <b-tab :title="`${msg('Furniture')}`">
          <div>


            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>

                  <real-estate-furniture v-model="realEstate.realEstateFurnishing"
                                         @change="()=>realEstateUpdated=true"/>
                </div>
              </b-card-body>
            </b-card>


            <!-- resources-->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <real-estate-resources v-model="realEstate" @change="()=>realEstateUpdated=true"/>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-tab>
        <!--Contact details  -->
        <b-tab :title="msg('Contact')">
          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ msg('Contact').toUpperCase() }}
                    </h4>
                  </div>
                  <real-estate-contact v-model="realEstate" @change="()=>realEstateUpdated=true"/>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-tab>
        <!--Nuki details -->
        <b-tab :title="msg('Nuki')">

          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <!-- Header: Personal Info -->
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Nuki').toUpperCase() }}
                  </h4>
                </div>
                <real-estate-nuki v-model="realEstate" @change="()=>realEstateUpdated=true"/>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>

        <b-tab v-if="realEstate.marketingType.rent" :title="msg('Treasure Rent')">

          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <!-- Header: Personal Info -->
                <div class="d-flex">
                  <feather-icon icon="ContractIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Treasure Rent').toUpperCase() }}
                  </h4>
                </div>
                <real-estate-treasure-rent v-model="realEstate" @change="()=>realEstateUpdated=true"/>
              </div>
            </b-card-body>
          </b-card>


        </b-tab>
<!--
        <b-tab v-if="realEstate.marketingType.rent" :title="msg('Channels')">

          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                &lt;!&ndash; Header: Personal Info &ndash;&gt;
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Channels').toUpperCase() }}
                  </h4>
                </div>
                <real-estate-channels v-model="realEstate" @change="()=>realEstateUpdated=true"/>
              </div>
            </b-card-body>
          </b-card>


        </b-tab>
-->


        <b-tab v-if="realEstate.marketingType.rent" :title="msg('Expenses')">

          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <!-- Header: Personal Info -->
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('Expenses').toUpperCase() }}
                  </h4>
                </div>
                <real-estate-expenses v-model="realEstate" @loadRealEstate="loadRealEstate"/>
              </div>
            </b-card-body>
          </b-card>

        </b-tab>

        <b-tab :title="msg('Todo List')">

          <b-card class="card-stats ">
            <real-estate-todo v-model="realEstate" @change="()=>realEstateUpdated=true"/>

          </b-card>
        </b-tab>


<!--
        <b-tab :title="msg('Calendar')">


        </b-tab>
-->

        <!-- Damages Tab-->
        <b-tab :title="msg('Damages')" v-if="realEstate">
          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats" border-variant="warning">
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2 w-100">
                  <b-row>
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                      <i class="fa-solid fa-broom"></i>
                      <span class="align-middle ml-50">{{ msg('Damages') }}</span>
                    </b-col>
                    <b-col class="text-right">
                      <b-link style="color: inherit" @click="$refs.damage.refresh()">
                        <feather-icon icon="RefreshCcwIcon" size="16"/>
                      </b-link>
                    </b-col>
                  </b-row>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <damage-panel ref="damage" :realEstatesIds="realEstate.id"/>
              </b-card-body>
            </b-card>
          </b-col>
        </b-tab>

        <b-tab v-if="Array.isArray(realEstate.priceLabs)" title="PriceLabs">
          <b-card>
            <real-estate-price-labs v-for="priceLab in realEstate.priceLabs" v-bind:price-labs="priceLab"
                                    v-bind:key="priceLab.pms"/>
          </b-card>
        </b-tab>

      </b-tabs>

<!--      <template v-if="tabIndex === 10">
        <calendar-rent v-if="realEstate.bookings!=null&&realEstate.bookings.length>0" :property-id="realEstate.id"
                       is-month></calendar-rent>

      </template>-->
      <!--      <template
                v-if="realEstate.realEstateTreasure != null && realEstate.realEstateTreasure.review != null && thisPage">
              <script
                  type="application/javascript"
                  src="https://www.revyoos.com/js/widgetBuilder.js"
                  :data-revyoos-widget="realEstate.realEstateTreasure.review"
                  ref="revyoosScript"
              ></script>
            </template>-->

    </div>
    <div v-else>
      <div class="d-flex align-items-center" style="min-height: 600px">
        <div class="d-inline-block w-100 text-center">
          <b-spinner class="mx-1" variant="primary"/>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import RealEstateImageSwiper from '@/components/panels/realEstates/realEstateImageSwiper'
import RealEstateGeo from '@/components/panels/realEstates/realEstateGeo'
import RealEstateNuki from '@/components/panels/realEstates/realEstateNuki'
import RealEstateAdmin from "@/components/panels/realEstates/realEstateAdmin";
import RealEstateFurniture from '@/components/panels/realEstates/realEstateFurniture'
import RealEstatePrices from '@/components/panels/realEstates/realEstatePrices'
import RealEstateContact from '@/components/panels/realEstates/realEstateContactPerson'
import RealEstateArea from '@/components/panels/realEstates/realEstateAreas'
import RealEstateManagement from '@/components/panels/realEstates/realEstateManagement'
import RealEstateMetaEdit from '@/components/panels/realEstates/realEstateMetaEdit'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import RealEstateText from "@/components/panels/realEstates/realEstateTexts";
import {mergeDeep} from "@/plugins/custom-prototypes";
import RealEstateTreasureRent from "@/components/panels/realEstates/realEstateTreasureRent";
import CalendarRent from "@/views/calendar/CalendarRent";
import RealEstateExpenses from "@/components/panels/realEstates/realEstateExpenses";
import RealEstateTodo from "@/components/panels/realEstates/realEstateTodo";
import RealEstatePriceLabs from "@/components/panels/realEstates/RealEstatePriceLabs.vue";
import {PriceLabs} from "@/model/RealEstate/priceLabs/PriceLabsDetails";
import RealEstateChannels from "@/components/panels/realEstates/realEstateChannels.vue";
import DamagePanel from "@/components/panels/damages/DamagePanel.vue";
import RealEstateResources from "@/components/panels/realEstates/realEstateResources.vue";

const defaultRealEstate = {
  contactPerson: {},
  realEstateState: {},
  realEstateManagement: {},
  furnishes: {},
  realEstateTexts: {},
  realEstatePrices: {},
  realEstateTreasure: {},
  resources: {},
  attachments: [],
  priceLabs: []
};

export default {
  name: 'editRealEstate',
  components: {
    DamagePanel,
    RealEstatePriceLabs,
    // eslint-disable-next-line vue/no-unused-components
    RealEstateText,
    RealEstateResources,
    // eslint-disable-next-line vue/no-unused-components
    RealEstateMetaEdit,
    RealEstateGeo,
    RealEstateNuki,
    RealEstateAdmin,
    RealEstateArea,
    // eslint-disable-next-line vue/no-unused-components
    RealEstateFurniture,
    RealEstateContact,
    RealEstateExpenses,
    RealEstateTodo,
    RealEstatePrices,
    RealEstateManagement,
    RealEstateImageSwiper,
    vueDropzone: vue2Dropzone,
    StatisticCardHorizontal,
    RealEstateTreasureRent,
    // eslint-disable-next-line vue/no-unused-components
    RealEstateChannels,
    // eslint-disable-next-line vue/no-unused-components
    CalendarRent
  },
  data() {
    return {
      loaded: false,
      error: null,
      thisPage: false,

      realEstate: {name: '', furnishes: {}, resources: {}, realEstateCancel: {}},
      building: {},
      tabIndex: 0,
      saveButton: 0,
      requestId: null,
      realEstateUpdated: false,
      dropzoneOptions: {},

      uploadFile: false,

      images: [{
        id: 1,
        imgSrc: require('@/assets/images/icons/pdf.png')
      }
      ],
      //Listeners
      listeners: {
        submit: () => {
        }
      },
    }
  },
  mounted() {
    this.thisPage = true;

  },
  beforeDestroy() {
    /* this.thisPage = false;

     console.log("beforeDestroy")
     window.document.getElementById('scp_iframe_general').remove();*/
  },

  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
  }
  ,
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getAllLanguage', 'getAllBranches', 'getAllCountries', 'getAdminRoleId']),
    ...mapGetters('realEstate', ['fileTags']),
    dropzoneOptionsFiles() {
      if (this.realEstate == null || this.realEstate.id == null)
        return {};
      return {
        url: window.config.apiUrl + 'realEstate/upload/file/' + this.realEstate.id,
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        maxFiles: 10, // Set to desired limit or remove for unlimited
        //uploadMultiple: true, // Allows multiple files in a single request if supported
        multiple: true, // Allows multiple files to be uploaded if supported
        parallelUploads: 10, // Number of files to upload at once
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 10000000000,
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.mp4,.mp3",
      }
    }
    ,
    canSubmit: {
      get() {
        return false
      }
      ,
      save(v) {
        this.$emit('update:canSave', v)
      }
    }
    ,
    allBranches() {
      return Object.values(this.getAllBranches)
    }
    ,
  }
  ,
  created() {
    if (this.$route.params.id == null) {
      return this.error = 'Invalid realEstate id'
    }
    this.id = this.$route.params.id;
    this.requestId = this.$route.params.requestId;
    Promise.all([this._loadRealEstateData()])
        .finally(() => this.onLoad())
  }
  ,

  methods: {
    ...
        mapActions('document', ['downloadCustomFile']),
    ...
        mapActions('realEstate', {
          _getRealEstate: 'getRealEstateById',
          _getRealEstateWithBuilding: 'getRealEstateByIdWithBuilding',
          _updateRealEstate: 'updateRealEstate',
          _updateDocumentFile: 'updateDocumentFile',
          _loadRealEstateData: 'getData'
        }),
    uploadCompleteFile(file, response) {
      let responseData = JSON.parse(file.xhr.responseText);
      let realEstate = responseData.objects["RealEstate"];
      this.realEstate = realEstate;
    }
    ,
    uploadSuccessFile(file, response) {
      try {
        if (response.data == null || response.data['RealEstate'] == null)
          return;
        this.realEstate = response.data['RealEstate'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    }
    ,

    onHideFile(index) {
      let file = this.realEstate.files[index];
      file.visible = false;
      file.visibleForOwner = false;
      file.active = false;
      this.submitDocumentFile(index)
    }
    ,
    submitDocumentFile(index) {
      this._updateDocumentFile({
        id: this.realEstate.id,
        file: this.realEstate.files[index]
      });
    }
    ,
    onDownloadFile(fileId) {
      let $this = this;
      this.downloadCustomFile({fileId: fileId}).then(file => {
        $this.asyncDownloadFile(file)
      })
    }
    ,
    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return {far: true, 'fa-file-pdf': true}
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return {far: true, ' fa-file-image': true}
      }
      if (ext === 'doc' || ext === 'docx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {far: true, ' fa-file-archive': true}
      }
      return {fas: true, 'fa-file-alt': true}
    }
    ,
    onLoad() {
      this.loaded = false

      this.loadRealEstate()

    }
    ,
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.realEstate.description === html)
        return;
      this.realEstate.description = html;
      this.onValueUpdated({quill, html, text})
    }
    ,
    loadDropZone() {
      this.dropzoneOptions = {
        url: window.config.apiUrl + 'realEstate/upload/img',
        headers: {
          'Authorization': `Bearer ${localStorage.Bearer}`,
          'realEstateId': this.realEstate.id
        },
        paramName: 'file', // The name expected by the backend for the file parameter
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 100, // Adjust to suitable size in MB
        maxFiles: 10, // Adjust to desired file limit
        uploadMultiple: true, // Send multiple files in a single request
        multiple: true, // Allow multiple files to be uploaded
        parallelUploads: 10, // Number of files to process in one go
        acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.mp4,.mp3"
      };
    }

    ,
    updatedHours: function (val) {
      // do whatever you want here
      console.log('updatedHours', val)
    }
    ,
    loadRealEstate() {
      let $this = this
      $this.loaded = false
      this._getRealEstateWithBuilding($this.id)
          .then((ans) => {
            let realEstate = ans["RealEstate"];
            console.log('the realEstate is', realEstate)
            realEstate = mergeDeep(realEstate, defaultRealEstate);
            realEstate.priceLabs = (realEstate.priceLabs || []).map(p => PriceLabs(p))
            $this.realEstate = realEstate;
            $this.building = ans["Building"];
            //Set page as loaded
            $this.loaded = true
            $this.realEstateUpdated = false;
            $this.checkNullObjects(realEstate);
            $this.loadDropZone();
          }, (error) => {
            $this.error = error
          })
    }
    ,
    checkNullObjects(realEstate) {
      if (realEstate.realEstateManagement == null) {
        realEstate.realEstateManagement = {}
      }
      if (realEstate.realEstateState == null) {
        realEstate.realEstateState = {}
      }
      if (realEstate.contactPerson == null) {
        realEstate.contactPerson = {}
      }
      if (realEstate.attachments == null) {
        realEstate.attachments = []
      }
      if (realEstate.realEstateFurnishing == null) {
        realEstate.realEstateFurnishing = {}
      }
      if (realEstate.realEstateTexts == null) {
        realEstate.realEstateTexts = {}
      }
      if (realEstate.realEstatePrices == null) {
        realEstate.realEstatePrices = {}
      }

      if (realEstate.realEstateCancel == null) {
        realEstate.realEstateCancel = {}
      }

      if (realEstate.realEstateCancel.threeDaysFeePercent == null) {
        realEstate.realEstateCancel.threeDaysFeePercent = 20;
      }
      if (realEstate.realEstateCancel.sevenDaysFeePercent == null) {
        realEstate.realEstateCancel.sevenDaysFeePercent = 15;
      }
      if (realEstate.realEstateCancel.fourteenDaysFeePercent == null) {
        realEstate.realEstateCancel.fourteenDaysFeePercent = 10;
      }

    }
    ,
    uploadComplete(file, response) {
      this.loadRealEstate()

      console.log('uploadComplete', {file, response})
    }
    ,
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['RealEstate'] == null)
          return;
        this.realEstate.images = response.data['RealEstate']['images'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    }
    ,
    updateRealEstate() {
      let $this = this
      this._updateRealEstate(this.realEstate)
          .then(
              (a) => $this.$emit('submitted', {
                status: true,
                server: a
              }),
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    }
    ,
    validate(field) {
      return this.$v.realEstate[field] != null && this.$v.realEstate[field].$error === true
    }
    ,
    submit() {
      if (this.id !== 0) {
        return this.updateRealEstate()
      }
      return this.createRealEstate()

    }
    ,


  }
  ,

}
</script>

<style>
.realEstate-page .update-realEstate {
  position: fixed;
  bottom: 5%;
  z-index: 100;
  transition: all 0.5s ease;
  right: 79px;
  opacity: 0;
}

.realEstate-page .update-realEstate.active {
  opacity: 1;
}

.realEstate-page .update-realEstate .btn-primary {
  -webkit-box-shadow: 0 1px 20px 1px #7367f0 !important;
  box-shadow: 0 1px 20px 1px #7367f0 !important;
}

.realEstate-page .update-realEstate .btn-warning {
  -webkit-box-shadow: 0 1px 20px 1px #ff8510 !important;
  box-shadow: 0 1px 20px 1px #ff8510 !important;
}

.realEstate-page .dz-preview, .dz-file-preview {
  display: none;
}
</style>
