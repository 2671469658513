<template>
  <b-modal ref="damageModal" size="xl" title="Damage" centered hide-footer content-class="p-0" no-close-on-backdrop body-class="p-0">
    <div class="p-1" v-if="damages" style="min-height: 80vh">
      <b-row>
        <b-col cols="3">
          <b-form-group v-bind:label="msg('reportedTime')+':'" label-for="input-2">
            <b-form-input size="sm" v-model="damages.reportedTime" readonly/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group v-bind:label="msg('address')+':'" label-for="input-2">
            <b-form-input size="sm" v-model="realEstate.fullAddress" readonly/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group v-bind:label="msg('clientName')+':'" label-for="input-2">
            <b-form-input size="sm" v-model="damages.clientName" readonly/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group v-bind:label="msg('reportedBy')+':'" label-for="input-2">
            <b-form-input size="sm" v-model="damages.reportedBy" readonly/>
          </b-form-group>
        </b-col>
      </b-row>
      <div>
        <b-form-group class="m-0" v-bind:label="msg('Damage')+':'" label-for="input-2">
          <b-form-textarea v-model="damages.damageDescription" rows="3"
                           placeholder="Enter damage description"></b-form-textarea>
        </b-form-group>
      </div>
      <div class="mb-1">
        <h5 class="my-1">Status</h5>
        <b-form-checkbox inline v-model="damages.newApartmentDamage">{{ msg('newApartmentDamage') }}</b-form-checkbox>
        <b-form-checkbox inline v-model="damages.isClientFault">{{ $t('isClientFault') }}</b-form-checkbox>
        <b-form-checkbox inline v-model="damages.paid">{{ $t('paid') }}</b-form-checkbox>
        <b-form-checkbox inline v-model="damages.paidByClient">{{ $t('paidByClient') }}</b-form-checkbox>
        <b-form-checkbox inline v-model="damages.damageFixed">{{ $t('damageFixed') }}</b-form-checkbox>
      </div>
      <div>
        <b-row>
          <b-col cols="4">
            <b-form-group v-bind:label="$t('damageFixedTime')+':'" label-for="input-2">
              <b-form-input size="sm" v-bind:value="damages.damageFixedTime ? $moment(damages.damageFixedTime).format('DD/MM/YY HH:mm') : null" disabled/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group v-bind:label="$t('damageCost')+':'" label-for="input-2">
              <b-form-input size="sm" v-model="damages.damageCost" type="number"/>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group v-bind:label="$t('damageFixedBy')+':'" label-for="input-2">
              <b-form-input size="sm" v-model="damages.damageFixedBy"/>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <!-- Original Resources List -->
        <div v-if="damages.missingResources!=null" class="original-resources mt-2">
          <h4 class="mb-1"><u> {{ msg('Missing Resources') }}</u></h4>
          <b-row>
            <b-col
                v-for="(value, key) in filteredMissingResources"
                :key="key"
                class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1"
            >
              <strong>{{ msg(formatResourceKey(key)) }}: </strong>     <span style="color: red"> {{ value }}</span>
            </b-col>
          </b-row>
        </div>
      </div>
      <div>
        <h5 class="my-1">Images</h5>
        <b-form-group v-if="damages && Array.isArray(damages.images) ">
          <b-img v-for="img in damages.images" v-bind:key="img"
                 v-bind:src="getUrl(`damage/image/${damages.id}/${img}`)" fluid
                 style="max-height: 150px;margin-right: 5px"
                 thumbnail></b-img>
        </b-form-group>
      </div>
      <div style="margin-bottom: 50px;">
        <vue-dropzone id="damage-dropzone" class="cleaning-dropzone"
                      :options="dropzoneImagesOptions"
                      :useCustomSlot="true"
                      :duplicateCheck="true"
                      @vdropzone-success="uploadImageSuccess">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title"><i class="fa-solid fa-upload"></i> Upload an image</h3>
          </div>
        </vue-dropzone>
      </div>
      <div class="px-1" style="position: absolute; bottom: 20px; left: 0; width: 100%">
        <b-button block v-on:click="trySaveDamage" variant="primary">
          {{ msg('Save') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapActions} from "vuex";
import vueDropzone from "vue2-dropzone";

const buildDamageObject = (damage) => {
  damage = damage || {};
  return {
    ...damage,
    id: damage.id,
    buildingId: damage.buildingId,
    realEstateId: damage.realEstateId,
    contractId: damage.contractId,
    cleaningTaskId: damage.cleaningTaskId,
    clientId: damage.clientId,
    clientName: damage.clientName,
    branchId: damage.branchId,
    damageDescription: damage.damageDescription,
    images: damage.images || [],
    newApartmentDamage: damage.newApartmentDamage || false,
    isClientFault: damage.isClientFault || false,
    paid: damage.paid || false,
    paidByClient: damage.paidByClient || false,
    damageFixed: damage.damageFixed || false,
    damageFixedBy: damage.damageFixedBy || "",
    damageFixedTime: damage.damageFixedTime || "",
    reportedBy: damage.reportedBy || "",
    damageCost: damage.damageCost || "",
    reportedTime: damage.reportedTime || ""
  }
};

export default {
  name: "damageModal",
  // eslint-disable-next-line vue/no-unused-components
  components: {vueDropzone},
  data: () => ({
    damageId: null,
    damages: buildDamageObject(),
    realEstate: {}
  }),
  computed:{

    filteredMissingResources() {
      return Object.fromEntries(
          Object.entries(this.damages.missingResources).filter(([key, value]) => value > 0)
      );
    },
    dropzoneImagesOptions() {
      let $this = this;
      return {
        url: window.config.apiUrl + `damage/update/${$this.damageId}/upload/img`,
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`},
        createImageThumbnails: false,
        disablePreviews: true,
        thumbnailWidth: 25,
        thumbnailHeight: 25,
        addRemoveLinks: true,
        maxFilesize: 5000000,
        acceptedFiles: 'image/*',
      }
    }
  },
  methods: {
    ...mapActions('damage', ['getById', 'updateDamage']),
    showById(id) {
      let $this = this;
      this.damageId = id;
      this.getById(id).then(response => {
        let damages = response.Damages;
        let realEstate = response.RealEstate;
        console.log("damageModal -> showById", response);
        $this.damages = damages;
        $this.realEstate = realEstate;
        this.$refs.damageModal.show();
      });
    },
    formatResourceKey(key) {
      return key
          .replace(/([A-Z])/g, " $1") // Insert a space before capital letters
          .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    },
    trySaveDamage() {
      let $this = this;
      this.updateDamage({id: this.damageId, request: this.damages}).then(response => {
        console.log("damageModal -> trySaveDamage", response);
        $this.$emit('updated',response.Damages);
        $this.damages = buildDamageObject(response.Damages);
      });
    },
    uploadImageSuccess(file, response) {
      console.log("uploadImageSuccess", {response, file})
      if (response == null || response.objects == null || response.objects['id'] == null)
        return;
      if(this.damages.images == null) {
        this.damages.images = [];
      }
      this.damages.images.push(response.objects['id']);
    },
  },
}
</script>


<style scoped>

</style>
